<template>
  <header class="header">
    <div class="header-content">
      <div class="top-text">
        <h1>
          <span class="highlight">Изучайте</span> слова <br> из <span class="highlight">Корана</span> легко
        </h1>
        <p>
          Углубитесь в понимание священного текста<br>с помощью нашего приложения и изучите<br> 
          <strong style="font-weight: 500;">80%</strong> всего Корана!
        </p>
      </div>
      <div class="cta-container">
        <!-- Добавляем событие клика -->
        <button class="cta-btn" @click="scrollToFeatures">Попробовать сейчас</button>
      </div>
    </div>
    <div class="image-container">
      <img src="../assets/readQuran.png" alt="QuranReader" class="quran-image">
    </div>
  </header>
</template>


<script>
export default {
  name: 'HeaderSection',
  methods: {
    scrollToFeatures() {
      const featuresSection = document.getElementById('features');
      if (featuresSection) {
        featuresSection.scrollIntoView({ behavior: 'smooth' });
      }
    },
  }
};
</script>

<style scoped>
/* Основной стиль */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 240px;
  color: white;
  min-height: 100vh;
  border-radius: 24px;
  box-sizing: border-box;
}

.header-content {
  max-width: 50%;
  display: grid;
  gap: 20px;
  align-items: start;
  height: auto;
}

.header h1 {
  font-size: 60px;
  line-height: 71px;
  font-weight: 500;
  margin: 0;
}

.header p {
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
}

.cta-container {
  margin-top: 20px;
}

.cta-btn {
  background-color: #FFCE61;
  color: #5350D5;
  border: none;
  height: 60px;
  padding: 0 30px;
  border-radius: 30px;
  font-size: 25px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s, transform 0.3s;
}

.cta-btn:hover {
  background-color: #FFF;
  color: #5350D5;
  transform: scale(1.05);
}

.highlight {
  background: #FFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.image-container {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quran-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 16px;
}

/* Адаптивность для планшетов */
@media (max-width: 1200px) {
  .header {
    padding: 20px;
  }

  .header h1 {
    font-size: 50px;
    line-height: 60px;
  }

  .header p {
    font-size: 24px;
    line-height: 32px;
  }
}

/* Адаптивность для мобильных устройств */
@media (max-width: 900px) {
  .header {
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    text-align: center;
  }

  .header-content {
    max-width: 100%;
    text-align: center;
  }

  .header h1 {
    font-size: 36px;
    line-height: 44px;
  }

  .header p {
    font-size: 18px;
    line-height: 26px;
  }

  .quran-image {
    max-width: 100%;
    
  }

  .cta-btn {
    width: 100%;
    max-width: 300px;
  }
}

/* Адаптивность для очень маленьких экранов (телефоны) */
@media (max-width: 600px) {
  .header{
    flex-direction: column-reverse;
    padding: 30px 20px;
    justify-content: start;
  }
  .header h1 {
    font-size: 48px;
    line-height: 121%;
  }

  .header p {
    font-size: 18px;
    line-height: 22px;
  }

  .cta-btn {
    font-size: 20px;
    padding: 10px 20px;
  }

  .quran-image {
    margin-bottom: 20px;
  }
}
</style>
