<template>
  <footer class="footer">
    <p>&copy; 2024 QuranWords. Все права защищены.</p>
    <div class="social-links">
      <a href="https://t.me/quran_way_app" target="_blank">
        <img src="https://img.icons8.com/?size=100&id=oWiuH0jFiU0R&format=png&color=000000" alt="Telegram">
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterSection'
}
</script>

<style scoped>
.footer {
  color: white;
  padding: 30px 20px;
  text-align: center;
  margin-bottom: 0;
}

.footer p {
  margin: 0;
  font-size: 16px;
}

.social-links {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.social-links img {
  width: 30px;
  height: 30px;
  transition: transform 0.3s;
}

.social-links img:hover {
  transform: scale(1.2);
}

/* Адаптация для мобильных устройств */
@media (max-width: 480px) {
  .footer {
    padding: 20px 10px;
  }

  .footer p {
    font-size: 14px;
  }

  .social-links {
    gap: 10px;
  }

  .social-links img {
    width: 25px;
    height: 25px;
  }
}
</style>
