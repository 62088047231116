<template>
  <div id="app">
    <HeaderSection id="header" />
    <FeaturesSection id="features" />
    <ShowGame id="screenshots" />
    <DonationSection id="donation" />
    <DownloadSection id="download" />
    <FooterSection />
    <!-- Фиксированная кнопка для перехода вниз -->
    <button v-if="isMobile" class="scroll-to-bottom-btn" @click="scrollToBottom">
      Перейти к скачиванию
    </button>
  </div>
</template>

<script>
import HeaderSection from './components/HeaderSection.vue';
import FeaturesSection from './components/FeaturesSection.vue';
import DownloadSection from './components/DownloadSection.vue';
import FooterSection from './components/FooterSection.vue';
import ShowGame from './components/screenshot.vue';
import DonationSection from './components/Donation.vue';

export default {
  name: 'App',
  data() {
    return {
      activeSection: 'header',
      isMobile: window.innerWidth <= 768, // Флаг для мобильных устройств
    };
  },
  mounted() {
    this.observeSections();
    window.addEventListener('resize', this.checkMobile);
  },
  methods: {
    observeSections() {
  const sections = document.querySelectorAll('section');
  const options = {
    threshold: 0.5,
  };

  const observer = new IntersectionObserver(this.handleIntersect, options);

  sections.forEach((section) => {
    observer.observe(section);
  });
},
handleIntersect(entries) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const section = entry.target.id;
      if (this.activeSection !== section) {  // Не обновляем, если уже в этой секции
        this.activeSection = section;
      }
    }
  });
},

    scrollToBottom() {
      // Плавный скролл к низу страницы
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  },
  components: {
    HeaderSection,
    FeaturesSection,
    DownloadSection,
    FooterSection,
    DonationSection,
    ShowGame,
  },
};
</script>


<style>
body {
  margin: 0;
  font-family:  sans-serif;
  color: #333;
  background: linear-gradient(-180deg, #544191, #8763B2, #AC87BB, #AC87BB, #8763B2, #544191);

}

#app {
  position: relative;
}

/* Нижнее меню */
.bottom-menu {
  position: fixed;
  top: 0;
  left: 30%;
  width: 40%; /* Меню на всю ширину экрана */
  background-color: #fff;
  border-top: 1px solid #ddd;
  border-radius: 0 0 10px 10px; /* Закругление только для нижних углов */
  z-index: 1000;
}

.bottom-menu ul {
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 10px 0;
  list-style-type: none;
}
button{
  font-family: sans-serif;
}
.bottom-menu ul li {
  margin: 0;
}

.bottom-menu ul li a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  padding: 10px 15px;
  transition: color 0.3s ease;
}

.bottom-menu ul li a:hover {
  color: #7465E2;
}

.bottom-menu ul li a.active {
  font-weight: bold;
  color: #7465E2; /* Активный цвет */
  border-bottom: 2px solid #7465E2;
}


/* Фиксированная кнопка для перехода вниз */
.scroll-to-bottom-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 10px;
  background-color: #5350D5;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  z-index: 1000;
}

.scroll-to-bottom-btn:hover {
  background-color: #7465E2;
  transform: scale(1.1);
}

/* Скрываем кнопку на больших экранах */
@media (min-width: 769px) {
  .scroll-to-bottom-btn {
    display: none;
  }
}

</style>
