<template>
    <div class="games-container">
      <div
        v-for="(game, index) in games"
        :key="game.id"
        :class="['game-block', { 'reverse-layout': index % 2 !== 0 }]"
      >
        <div class="game-image">
          <img :src="game.image" :alt="game.title" />
        </div>
        <div class="game-info">
          <h2 v-html="game.mainTitle"></h2>
          <h3>{{ game.subTitle }}</h3>
          <p>{{ game.description }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ShowGame",
    data() {
      return {
        games: [
          {
            id: 1,
            mainTitle: "Познай контекст",
            subTitle: "Найдите перевод в аяте",
            description: "Погрузитесь в арабский текст: выделенное слово в аяте требует точного перевода. Уловите смысл в контексте! Ваша ассоциативная картинка подскажет значение, а подсказка поможет, если потребуется.",
            image: require("@/assets/scr1.png"),
          },
          {
            id: 2,
            mainTitle: "Соедините образы<br>и значения",
            subTitle: "Выберите перевод",
            description: "Перед вами слово на арабском и яркое изображение, раскрывающее его суть. Вам нужно лишь выбрать верный перевод из четырёх предложенных. Отличная тренировка для ассоциаций и памяти!",
            image: require("@/assets/scr2.png"),
          },
          {
            id: 3,
            mainTitle: "Согласуется или нет?",
            subTitle: "Проверьте интуицию",
            description: "Здесь всё просто: видите картинку и слово на экране? Одним кликом решите, подходят ли они друг другу — “Да” или “Нет”. Ваша интуиция станет главным помощником.",
            image: require("@/assets/scr4.png"),
          },
          {
            id: 4,
            mainTitle: "Соберите слово",
            subTitle: "Перетаскивайте буквы",
            description: "Пазл из букв ждёт вас! Переносите буквы в контейнер и составляйте полное слово. Так вы закрепите знания и научитесь внимательности к каждому арабскому символу.",
            image: require("@/assets/scr5.png"),
          },
          {
            id: 5,
            mainTitle: "Соедините слово<br> и образ",
            subTitle: "Найдите пару",
            description: "Увидели четыре картинки и одно арабское слово? Ваша задача — соотнести изображение с правильным значением. Это отличное упражнение для ассоциативного мышления!",
            image: require("@/assets/scr6.png"),
          },
          {
            id: 6,
            mainTitle: "Закрепите знания",
            subTitle: "Соедините переводы",
            description: "Заключительная игра для знатоков! Вас ждут четыре слова на арабском и четыре перевода — соедините их. Это итог всех ваших усилий и знания в одной задаче!",
            image: require("@/assets/scr3.png"),
          },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .games-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 66px 240px;
  }

  .game-block {
    display: flex;
    align-items: center;
    gap: 66px;
  }
  
  .game-block.reverse-layout {
    flex-direction: row-reverse;
  }
  
  .game-image img {
    width: 260px;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
  }
  
  .game-info {
    max-width: 600px;
    text-align: left;
  }
  
  .game-info h2 {
    font-size: 50px;
    margin: 0;
    font-weight: 500;
    color: #FFF;
  }
  
  .game-info h3 {
    font-weight: 400;
    font-size: 30px;
    margin-top: 6px;
    color: #FFF;
  }
  
  .game-info p {
    color: #FFF;
    font-weight: 400;
    line-height:19px;
    margin-top: 18px;
    font-size: 16px;
  }
  
  @media (max-width: 1200px) {
    .games-container {
      padding: 0 40px;
    }
  }
  
  @media (max-width: 768px) {
    .games-container {
      padding: 40px 20px;
    }
    .game-block {
      flex-direction: column;
      text-align: center;
      gap: 20px;

    }
    .game-block.reverse-layout {
      flex-direction: column;
    }
    .game-image img {
      width: 100%;
      height: auto;

    }
    .game-info h2 {
      font-size: 1.8em;
      text-align: center;
    }
    .game-info h3 {
      font-size: 1.5em;
      text-align: center;
      margin-top: 20px;

    }
    .game-info p {
      font-size: 1.1em;
      text-align: center;

    }
  }
  </style>
  