<template>
  <section id="download" class="download">
    <h2>Скачайте приложение сейчас</h2>
    <div class="download-buttons">
      <a href="https://www.feedinghungryminds.co.uk/wp-content/uploads/2022/11/Google-play-logo.png" target="_blank">
        <img :src="googlePlay" alt="Google Play">
      </a>
      <a href="https://beemden.nl/wp-content/uploads/download-on-the-app-store-logo-png-23.png" target="_blank">
        <img :src="appStore" alt="App Store">
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DownloadSection',
  data() {
    return {
      googlePlay: 'https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg',
      appStore: 'https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg'
    }
  }
}
</script>

<style scoped>
#download {
  padding: 80px 20px;
  text-align: center;
}

.download h2 {
  font-size: 36px;
  margin-bottom: 40px;
  font-weight: 400;
  color: #FFF;
}

.download-buttons {
  display: flex;
  justify-content: center;
  gap: 30px;
  height: 60px;
}

.download-buttons img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: transform 0.3s;
}

.download-buttons img:hover {
  transform: scale(1.05);
}

/* Адаптация для мобильных устройств */
@media (max-width: 480px) {
  #download {
    padding: 20px 20px;
  }

  .download h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .download p {
    font-size: 16px;
    line-height: 121%;
  }


  .download-buttons {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    height: auto;
  }

  .download-buttons img {
    height: auto;
    width: 200px;
  }
}
</style>
