<template>
  <section id="features" class="features">
    <h2>Возможности приложения</h2>
    <div class="features-container">
      <div class="feature-item" v-for="(feature, index) in limitedFeatures" :key="index">
        <img :src="feature.icon" :alt="feature.altText">
        <h3>{{ feature.title }}</h3>
        <p>{{ feature.description }}</p>
      </div>
    </div>
    <!-- Кнопка будет видна только на мобильных устройствах -->
    <button v-if="!showAll && isMobile" @click="showAll = true" class="show-more-btn">Показать больше</button>
  </section>
</template>

<script>
export default {
  name: 'FeaturesSection',
  data() {
    return {
      showAll: false,
      isMobile: window.innerWidth <= 768,
      features: [
        {
          icon: require('@/assets/iconixto/solid/book-open.svg'),
          altText: 'Кастомизация',
          title: 'Кастомизация Корана',
          description: 'Изменяйте внешний вид и настройки приложения для удобного изучения.'
        },
        {
          icon: require('@/assets/iconixto/solid/gamepad.svg'),
          altText: 'Игры для изучения',
          title: 'Игры для изучения',
          description: 'Участвуйте в увлекательных играх, чтобы легко запоминать новые слова.'
        },
        {
          icon: require('@/assets/iconixto/solid/grid-2.svg'),
          altText: 'Мультиязычный интерфейс',
          title: 'Мультиязычность',
          description: 'Учите арабский язык на русском, английском или чеченском языках.'
        },
        {
          icon: require('@/assets/iconixto/solid/chart-up.svg'),
          altText: 'Отслеживание прогресса',
          title: 'Трекер прогресса',
          description: 'Следите за количеством изученных слов и временем, затраченным на обучение.'
        },
        {
          icon: require('@/assets/iconixto/solid/star.svg'),
          altText: 'Словарная поддержка',
          title: 'Словарная поддержка',
          description: 'Находите аяты с изучаемыми словами для лучшего понимания контекста.'
        },
        {
          icon: require('@/assets/iconixto/solid/document-star.svg'),
          altText: 'Образование и правила',
          title: 'Образование и правила',
          description: 'Изучайте правила словообразования в арабском языке.'
        }
      ]
    };
  },
  computed: {
    limitedFeatures() {
      return this.showAll || !this.isMobile ? this.features : this.features.slice(0, 3);
    }
  },
  mounted() {
    window.addEventListener('resize', this.checkMobile);
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  }
};
</script>

<style scoped>
.features {
  padding: 34px 240px;
  text-align: center;
  position: relative;
  min-height: 100vh;
}

.features h2 {
  font-size: 50px;
  line-height: 50px;
  font-weight: 500;
  margin-bottom: 50px;
  margin-top: 0;
  color: #FFF;
}

.features-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.feature-item {
  background-color: #F5F9FD;
  padding: 20px;
  border-radius: 14px;
  width: 250px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.feature-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 25px rgba(83, 80, 213, 0.2);
}

.feature-item img {
  width: 60px;
  height: 60px;
}

.feature-item h3 {
  font-size: 20px;
  margin-bottom: 17px;
  font-weight: 600;
  color: #544291;
}

.feature-item p {
  font-size: 16px;
  color: #544291;
  font-weight: 400;
}

/* Кнопка "Показать больше" */
.show-more-btn {
  background-color: #5350D5;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 30px;
  transition: background-color 0.3s;
}

.show-more-btn:hover {
  background-color: #7465E2;
}

/* Адаптивность */
@media (max-width: 768px) {
  .features {
    padding: 0 20px;
  }

  .features h2 {
    font-size: 32px;
    line-height: 38px;
  }

  .features-container {
    gap: 20px;
  }

  .feature-item {
    width: 100%;
    max-width: 300px;
  }

  .feature-item img {
    width: 50px;
    height: 50px;
  }

  .feature-item h3 {
    font-size: 18px;
  }

  .feature-item p {
    font-size: 14px;
  }
}
</style>
