<template>
  <div class="donation-section">
    <h1>Поддержите проект</h1>
    <p>
      Помогите нам развивать приложение для изучения слов из Корана. 
      Ваш вклад будет использован для улучшения игр, добавления новых функций и 
      повышения доступности для всех пользователей.
    </p>
    <button @click="showModal = true" class="donation-btn">
      Поддержать
    </button>

    <!-- Модальное окно -->
    <div v-if="showModal" class="modal-overlay" @click.self="closeModal">
      <div class="modal-content">
        <h2>Поддержите проект</h2>
        <iframe 
          src="https://pay.cloudtips.ru/p/9f7e1421" 
          class="donation-iframe" 
          frameborder="0">
        </iframe>
        <button @click="closeModal" class="close-btn">Закрыть</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DonationSection',
  data() {
    return {
      showModal: false, // Управляет видимостью модального окна
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
/* Секция пожертвования */
.donation-section {
  padding: 50px 240px;
  text-align: center;
  font-family: sans-serif;
  margin: 20px auto;
  width: 100%;
  box-sizing: border-box; /* Учитываем padding в ширине */
}

.donation-section h1 {
  font-size: 50px;
  margin-bottom: 20px;
  font-weight: 500;
  color: #FFF;
}

.donation-section p {
  font-size: 30px;
  font-weight: 300;
  line-height: 36px;
  margin-bottom: 30px;
  color: #FFF;
}

.donation-btn {
  background-color: #FFCE61;
  color: #5350D5;
  padding: 15px 30px;
  font-weight: 400;
  font-size: 25px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.donation-btn:hover {
  background-color: #FFF;
  color: #7465E2;
  transform: scale(1.05);
}

/* Модальное окно */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  color: #5350D5;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  text-align: center;
}

.donation-iframe {
  width: 100%;
  height: 400px;
  border: none;
}

.close-btn {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #5350D5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Адаптация для мобильных устройств */
@media (max-width: 600px) {
  .donation-section {
    padding: 20px;
    background-color: #7465E2;
  }

  .donation-section h1 {
    font-size: 2rem;
  }

  .donation-section p {
    font-size: 1.2rem;
    line-height: 121%;
  }

  .donation-btn {
    font-size: 1.4rem;
    padding: 10px 20px;
  }

  .modal-content {
    width: 95%;
  }

  .donation-iframe {
    height: 300px;
  }

  .close-btn {
    font-size: 1rem;
    padding: 8px 16px;
  }
}
</style>
