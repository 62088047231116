import { createApp } from 'vue'
import { create, NCarousel, NCarouselItem } from 'naive-ui'
import App from './App.vue'

const naive = create({
  components: [NCarousel, NCarouselItem]
})

const app = createApp(App)
app.use(naive)
app.mount('#app')
